@charset "UTF-8";
html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

body {
  line-height: 1; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

nav ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after, q:before, q:after {
  content: none; }

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none; }

mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold; }

del {
  text-decoration: line-through; }

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0; }

input, select {
  vertical-align: middle; }

main {
  display: block; }

/* BASE COLOR
------------------------------------------------------------*/
/* SITE SETTING
------------------------------------------------------------*/
@media screen and (min-width: 769px) {
  * {
    margin: 0;
    padding: 0;
    zoom: 1; }
  *, *:before, *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box; }
  html {
    -webkit-text-size-adjust: none; }
  body {
    color: #3b3b3b;
    background: #FFF;
    font-size: 15px;
    line-height: 1.8;
    font-family: "M PLUS Rounded 1c","Yu Gothic","游ゴシック",YuGothic,"Hiragino Kaku Gothic Pro","メイリオ", Meiryo,"MS Pゴシック", Helvetica, Arial, Verdana, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt";
    font-feature-settings: "palt";
    letter-spacing: 0.024em; }
  h1, h2, h3, h4, h5, h6 {
    font-weight: 500; }
  img {
    -ms-interpolation-mode: bicubic;
    vertical-align: bottom; }
  i, strong {
    font-style: normal; }
  a {
    text-decoration: none;
    outline: none;
    cursor: pointer;
    color: #3b3b3b; }
    a:hover {
      text-decoration: none;
      color: #555555; }
      a:hover img {
        opacity: .8; }
  .clear {
    clear: both; }
  .clearfix:after, .COMPANY.first dl.detail:after {
    content: "";
    display: block;
    clear: both; }
  .display {
    width: 970px;
    margin: 0 auto;
    position: relative;
    top: 0;
    left: 0; }
  ul, ol {
    list-style: none; }
  input[type="text"],
  input[type="tel"],
  input[type="email"],
  input[type="submit"],
  textarea {
    -webkit-appearance: none; } }

@media screen and (max-width: 768px) {
  * {
    margin: 0;
    padding: 0;
    zoom: 1; }
  *, *:before, *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box; }
  html {
    -webkit-text-size-adjust: none; }
  body {
    color: #3b3b3b;
    background: #FFF;
    font-size: 13px;
    line-height: 1.8;
    font-family: "M PLUS Rounded 1c","Yu Gothic","游ゴシック",YuGothic,"Hiragino Kaku Gothic Pro","メイリオ", Meiryo,"MS Pゴシック", Helvetica, Arial, Verdana, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt";
    font-feature-settings: "palt";
    letter-spacing: 0.024em; }
  h1, h2, h3, h4, h5, h6 {
    font-weight: 500; }
  img {
    -ms-interpolation-mode: bicubic;
    vertical-align: bottom; }
  i, strong {
    font-style: normal; }
  a {
    text-decoration: none;
    outline: none;
    cursor: pointer;
    color: #3b3b3b; }
    a:hover {
      text-decoration: none;
      color: #555555; }
      a:hover img {
        opacity: .8; }
  .clear {
    clear: both; }
  .clearfix:after, .COMPANY.first dl.detail:after {
    content: "";
    display: block;
    clear: both; }
  .display {
    width: auto;
    margin: 0 auto;
    position: relative;
    top: 0;
    left: 0; }
  ul, ol {
    list-style: none; }
  input[type="text"],
  input[type="tel"],
  input[type="email"],
  input[type="submit"],
  textarea {
    -webkit-appearance: none; } }

#HEADER {
  /* PC STYLE
	----------------------------------------*/
  /* SP STYLE
	----------------------------------------*/
  /* CUSTUM STYLE
	----------------------------------------*/
  font-size: 24px; }
  @media screen and (min-width: 769px) {
    #HEADER {
      width: 100%;
      height: 104px;
      position: fixed;
      top: 0;
      left: 0;
      padding: 0 0 0 0;
      background: #FFF;
      z-index: 1000; }
      #HEADER .logo {
        float: left;
        margin: 36px 0 0 0; }
        #HEADER .logo img {
          width: 200px;
          height: auto; }
      #HEADER .gnavi {
        float: left;
        margin: 40px 0 0 30px; }
        #HEADER .gnavi li {
          list-style: none;
          margin-top: 0;
          float: left;
          margin-left: 20px; }
          #HEADER .gnavi li:first-child {
            margin-left: 0; }
        #HEADER .gnavi ul {
          display: block !important; } }
  @media screen and (max-width: 768px) {
    #HEADER {
      width: 100%;
      height: 60px;
      position: fixed;
      top: 0;
      left: 0;
      padding: 0 0 0 0;
      background: #FFF;
      z-index: 1000; }
      #HEADER .logo {
        float: left;
        margin: 15px 0 0 15px; }
        #HEADER .logo img {
          width: auto;
          height: 30px; }
      #HEADER .gnavi {
        float: right;
        margin: 15px 12px 0 0;
        background: url(../../images/common/responsive_nav_2x.png) no-repeat 0 0;
        background-size: 32px 64px;
        width: 32px;
        height: 32px;
        cursor: pointer; }
        #HEADER .gnavi li {
          list-style: none;
          margin-top: 16px;
          float: none; }
        #HEADER .gnavi.addClose {
          background: url(../../images/common/responsive_nav_2x.png) no-repeat 0 -32px;
          background-size: 32px 64px; }
        #HEADER .gnavi ul {
          -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12);
          box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12);
          position: absolute;
          width: 100%;
          top: 60px;
          left: 0;
          display: none; }
          #HEADER .gnavi ul li {
            margin: 0;
            border-top: #DDD 1px solid; }
            #HEADER .gnavi ul li a {
              background: #FFF;
              color: #3b3b3b;
              display: block;
              padding: 16px 15px; }
              #HEADER .gnavi ul li a:hover {
                opacity: .9; } }
  #HEADER a {
    color: #3b3b3b; }
    #HEADER a:hover {
      opacity: .8; }
  #HEADER .logo a {
    color: #3b3b3b;
    font-weight: 700;
    position: relative;
    line-height: 1; }
    #HEADER .logo a:hover {
      color: #2db7ee; }
      #HEADER .logo a:hover:after {
        content: '';
        width: 100%;
        height: 2px;
        background: #49c1f0;
        position: absolute;
        left: 0;
        bottom: -4px;
        border-radius: 6px; }
  #HEADER .gnavi {
    line-height: 1; }
    #HEADER .gnavi li {
      border-left: #3b3b3b 1px solid;
      padding: 0 0 0 20px; }
      #HEADER .gnavi li a {
        position: relative; }
        #HEADER .gnavi li a:hover {
          color: #2db7ee; }
          @media screen and (min-width: 769px) {
            #HEADER .gnavi li a:hover:after {
              content: '';
              width: 100%;
              height: 2px;
              background: #49c1f0;
              position: absolute;
              left: 0;
              bottom: -4px;
              border-radius: 6px; } }
      @media screen and (max-width: 768px) {
        #HEADER .gnavi li {
          border: none;
          padding: 0;
          font-size: 18px; } }
  #HEADER nav.sns {
    margin: 28px 0 0;
    float: right; }
    @media screen and (max-width: 768px) {
      #HEADER nav.sns {
        display: none; } }
    #HEADER nav.sns ul li {
      list-style: none;
      float: left;
      margin: 0 16px 0 0;
      width: 41px; }
      #HEADER nav.sns ul li img {
        width: 100%; }
      #HEADER nav.sns ul li:last-child {
        margin: 0; }

#FOOTER {
  /* PC STYLE
	----------------------------------------*/
  /* SP STYLE
	----------------------------------------*/
  /* CUSTUM STYLE
	----------------------------------------*/
  font-size: 16px;
  color: #FFF; }
  @media screen and (min-width: 769px) {
    #FOOTER {
      width: 100%;
      height: auto;
      position: relative;
      top: 0;
      left: 0;
      padding: 52px 0 60px 0;
      background: #49c1f0;
      z-index: 1; }
      #FOOTER .fnav {
        text-align: center;
        padding: 0 0 0 0; }
        #FOOTER .fnav li {
          list-style: none;
          margin-top: 0;
          display: inline-block;
          margin-left: 40px; }
          #FOOTER .fnav li:first-child {
            margin-left: 0; }
      #FOOTER .copy {
        display: block;
        font-size: 16px;
        color: #FFF;
        padding: 0 0 0 0;
        text-align: center; } }
  @media screen and (max-width: 768px) {
    #FOOTER {
      width: 100%;
      height: auto;
      position: relative;
      top: 0;
      left: 0;
      padding: 30px 12px 30px 12px;
      background: #49c1f0;
      z-index: 1; }
      #FOOTER .fnav {
        text-align: center;
        padding: 0 0 0 0;
        border: #FFF 1px solid;
        border-radius: 6px;
        overflow: hidden; }
        #FOOTER .fnav li {
          list-style: none;
          margin-top: 12px;
          display: inline-block;
          margin-left: 12px; }
          #FOOTER .fnav li:first-child {
            margin-left: 0; }
        #FOOTER .fnav li {
          float: none;
          text-align: left;
          display: block;
          margin: 0;
          padding: 0;
          border-bottom: #FFF 1px solid; }
          #FOOTER .fnav li:last-child {
            border: none; }
          #FOOTER .fnav li a {
            background: #FFF;
            color: #3b3b3b;
            display: block;
            padding: 12px 12px; }
            #FOOTER .fnav li a:hover {
              opacity: .9; }
      #FOOTER .copy {
        display: block;
        font-size: 10px;
        color: #FFF;
        padding: 20px 0 0 0;
        text-align: center; } }
  #FOOTER a {
    color: #FFF; }
    #FOOTER a:hover {
      text-decoration: underline; }
  #FOOTER .footlogo {
    margin: 40px 0;
    text-align: center; }
    @media screen and (max-width: 768px) {
      #FOOTER .footlogo {
        margin: 20px auto;
        width: 88%; }
        #FOOTER .footlogo img {
          width: 100%; } }
  @media screen and (max-width: 768px) {
    #FOOTER .fnav {
      border: none; }
      #FOOTER .fnav li {
        border-bottom: #e5e5e5 1px solid; }
        #FOOTER .fnav li a {
          text-decoration: none; }
        #FOOTER .fnav li:last-child {
          border: none; } }

.INDEX.firstview figure {
  border: #49c1f0 1px solid;
  border-radius: 8px;
  overflow: hidden; }
  @media screen and (max-width: 768px) {
    .INDEX.firstview figure {
      border: none;
      border-radius: 0px; } }
  .INDEX.firstview figure img {
    width: 100%; }

.INDEX.news .newsBox {
  border: #ea5c9d 1px solid;
  border-radius: 8px;
  margin: 0 0 40px;
  overflow: hidden; }
  @media screen and (max-width: 768px) {
    .INDEX.news .newsBox {
      margin: 0 0 24px; } }
  .INDEX.news .newsBox h3 {
    color: #FFF;
    text-align: center;
    background: #ea5c9d;
    font-size: 24px;
    padding: 12px 0; }
    @media screen and (max-width: 768px) {
      .INDEX.news .newsBox h3 {
        font-size: 15px;
        padding: 12px 0; } }
  .INDEX.news .newsBox ol {
    height: 260px;
    overflow: auto; }
    @media screen and (max-width: 768px) {
      .INDEX.news .newsBox ol {
        height: 180px; } }
    .INDEX.news .newsBox ol li {
      position: relative;
      border-bottom: #999 1px dashed;
      padding: 8px 12px;
      font-size: 20px; }
      @media screen and (max-width: 768px) {
        .INDEX.news .newsBox ol li {
          padding: 8px 15px;
          font-size: 13px; } }
      .INDEX.news .newsBox ol li:last-of-type {
        border: none; }
      .INDEX.news .newsBox ol li:before {
        content: '';
        display: inline-block;
        width: 92px;
        margin: 0 20px 0 0;
        font-size: 16px;
        text-align: center;
        color: #FFF;
        border-radius: 999px; }
        @media screen and (max-width: 768px) {
          .INDEX.news .newsBox ol li:before {
            font-size: 13px;
            margin: 0 0 4px; } }
      .INDEX.news .newsBox ol li.ns:before {
        content: 'ニュース';
        background: #999; }
      .INDEX.news .newsBox ol li.rs:before {
        content: 'リリース';
        background: #ea5c9d; }
      .INDEX.news .newsBox ol li a {
        color: #828282; }
        @media screen and (max-width: 768px) {
          .INDEX.news .newsBox ol li a {
            display: block; } }
        .INDEX.news .newsBox ol li a:hover {
          color: #ea5c9d; }
        .INDEX.news .newsBox ol li a time {
          display: inline-block; }
          .INDEX.news .newsBox ol li a time:after {
            content: '・・・・・';
            display: inline-block;
            margin: 0 12px; }

.INDEX.first .list {
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between; }
  @media screen and (max-width: 768px) {
    .INDEX.first .list {
      display: block; } }
  .INDEX.first .list li {
    width: 48.5%;
    list-style: none;
    border: #fa5e45 1px solid;
    border-radius: 8px;
    overflow: hidden;
    margin: 0 0 30px; }
    @media screen and (min-width: 769px) {
      .INDEX.first .list li:nth-last-child(-n+2) {
        margin: 0; } }
    @media screen and (max-width: 768px) {
      .INDEX.first .list li {
        width: 100%;
        margin: 0 0 15px; }
        .INDEX.first .list li:last-child {
          margin: 0; } }
    .INDEX.first .list li figure img {
      width: 100%; }
    .INDEX.first .list li dl {
      padding: 15px;
      text-align: center; }
      .INDEX.first .list li dl dt {
        background: #fa5e45;
        border-radius: 999px;
        color: #FFF;
        font-size: 26px; }
        @media screen and (max-width: 768px) {
          .INDEX.first .list li dl dt {
            font-size: 16px; } }
      .INDEX.first .list li dl dd {
        margin: 12px 0 0;
        font-size: 16px;
        color: #433f3f; }
        @media screen and (max-width: 768px) {
          .INDEX.first .list li dl dd {
            font-size: 13px;
            text-align: justify; }
            .INDEX.first .list li dl dd br {
              display: none; } }

.INDEX.second .boxAbout {
  border: #49c1f0 1px solid;
  border-radius: 8px;
  overflow: hidden; }

.INDEX.second h3.textLarge {
  text-align: center;
  margin: 20px 0;
  font-size: 28px; }
  @media screen and (max-width: 768px) {
    .INDEX.second h3.textLarge {
      margin: 15px;
      font-size: 20px;
      line-height: 1.6; } }
  .INDEX.second h3.textLarge strong {
    color: #49c1f0;
    display: inline-block;
    position: relative; }
    .INDEX.second h3.textLarge strong:before, .INDEX.second h3.textLarge strong:after {
      content: '';
      width: 100%;
      height: 1px;
      background: #49c1f0;
      position: absolute;
      left: 0;
      bottom: -2px; }
    .INDEX.second h3.textLarge strong:after {
      bottom: -5px; }

.INDEX.second .floating {
  margin: 0 20px; }
  @media screen and (max-width: 768px) {
    .INDEX.second .floating {
      margin: 0 15px; } }
  .INDEX.second .floating figure {
    float: left;
    margin: 0 56px 0 0;
    text-align: center; }
    @media screen and (max-width: 768px) {
      .INDEX.second .floating figure {
        float: none;
        margin: 0; }
        .INDEX.second .floating figure img {
          width: 64px; } }
    .INDEX.second .floating figure figcaption {
      font-size: 22px; }
      @media screen and (max-width: 768px) {
        .INDEX.second .floating figure figcaption {
          font-size: 16px;
          margin: 4px 0 0; } }
  .INDEX.second .floating p {
    padding: 10px 0 0; }

.INDEX.second figure.full {
  text-align: center;
  margin: 20px 20px 0; }
  @media screen and (max-width: 768px) {
    .INDEX.second figure.full {
      margin: 15px; } }
  .INDEX.second figure.full img {
    max-width: 100%; }

.INDEX.second .itemList {
  margin: 20px 8px 0;
  border-top: #49c1f0 1px solid; }
  @media screen and (max-width: 768px) {
    .INDEX.second .itemList {
      margin: 20px 0; } }
  .INDEX.second .itemList li {
    background: #fffff4;
    border-bottom: #49c1f0 1px solid;
    padding: 20px;
    color: #3b3b3b;
    font-size: 17px; }
    @media screen and (max-width: 768px) {
      .INDEX.second .itemList li {
        padding: 15px;
        font-size: 13px; } }
    .INDEX.second .itemList li:nth-child(2n+2) {
      background: #FFF; }
    .INDEX.second .itemList li:last-child {
      padding-left: 105px; }
      @media screen and (max-width: 768px) {
        .INDEX.second .itemList li:last-child {
          padding-left: 15px; } }
    .INDEX.second .itemList li figure {
      text-align: center;
      width: 30%;
      padding-right: 40px;
      float: left; }
      @media screen and (max-width: 768px) {
        .INDEX.second .itemList li figure {
          width: auto;
          float: none;
          padding: 0;
          margin: 0 0 15px; }
          .INDEX.second .itemList li figure img {
            width: 64px; } }
      .INDEX.second .itemList li figure figcaption {
        font-size: 22px; }
        @media screen and (max-width: 768px) {
          .INDEX.second .itemList li figure figcaption {
            font-size: 16px; } }
    @media screen and (max-width: 768px) {
      .INDEX.second .itemList li div.text br {
        display: none;
        text-align: justify; } }
    .INDEX.second .itemList li .harf {
      width: 48%;
      float: left; }
      @media screen and (max-width: 768px) {
        .INDEX.second .itemList li .harf {
          width: auto;
          float: none; } }
      .INDEX.second .itemList li .harf:last-of-type {
        float: right; }
        @media screen and (max-width: 768px) {
          .INDEX.second .itemList li .harf:last-of-type {
            float: none; } }
      .INDEX.second .itemList li .harf figure {
        width: auto; }

.INDEX.second .eating {
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  margin: 20px;
  position: relative; }
  @media screen and (max-width: 768px) {
    .INDEX.second .eating {
      display: block;
      margin: 15px; } }
  .INDEX.second .eating:before, .INDEX.second .eating:after {
    content: '';
    position: absolute; }
  .INDEX.second .eating:before {
    background: url("../../../images/index/cross_sky.png") no-repeat;
    width: 56px;
    height: 56px;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%); }
    @media screen and (max-width: 768px) {
      .INDEX.second .eating:before {
        width: 28px;
        height: 28px;
        background-size: 28px;
        transform: translateX(-50%) translateY(-110%); } }
  .INDEX.second .eating:after {
    background: url("../../../images/index/arrow_blue.png") no-repeat;
    width: 91px;
    height: 39px;
    bottom: -50px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2; }
    @media screen and (max-width: 768px) {
      .INDEX.second .eating:after {
        display: none; } }
  .INDEX.second .eating li {
    list-style: none;
    width: 45%;
    border: #49c1f0 2px solid;
    border-radius: 12px;
    padding: 10px 25px;
    background: #fffff4; }
    @media screen and (max-width: 768px) {
      .INDEX.second .eating li {
        width: auto;
        padding: 15px;
        margin: 0 0 56px; }
        .INDEX.second .eating li:last-child {
          margin: 0; } }
    .INDEX.second .eating li h4 {
      text-align: center;
      font-size: 24px;
      color: #49c1f0;
      margin: 0 0 8px; }
      @media screen and (max-width: 768px) {
        .INDEX.second .eating li h4 {
          font-size: 17px; } }
    .INDEX.second .eating li figure img {
      float: left;
      margin: 0 24px 0 0; }
      @media screen and (max-width: 768px) {
        .INDEX.second .eating li figure img {
          float: none;
          width: 100%;
          margin: 0 0 15px; } }
    .INDEX.second .eating li figure figcaption {
      white-space: nowrap;
      font-size: 15px;
      padding: 10px 0 0; }
      @media screen and (max-width: 768px) {
        .INDEX.second .eating li figure figcaption {
          text-align: center;
          font-size: 17px; } }
    .INDEX.second .eating li .text-center {
      padding: 10px 20px; }
      @media screen and (max-width: 768px) {
        .INDEX.second .eating li .text-center {
          padding: 15px;
          padding-bottom: 0; } }

.INDEX.second figure.last {
  position: relative; }
  .INDEX.second figure.last img {
    width: 100%; }
  .INDEX.second figure.last .amazon-btn {
    width: 376px;
    position: absolute;
    top: 346px;
    right: 22px; }
    @media screen and (max-width: 768px) {
      .INDEX.second figure.last .amazon-btn {
        width: 88%;
        display: block;
        margin: 15px auto;
        position: relative;
        top: 0;
        right: 0; } }

.INDEX.third .comment {
  position: relative;
  border-radius: 8px;
  border: #f9ba00 1px solid;
  overflow: hidden; }
  .INDEX.third .comment img {
    width: 100%; }

.INDEX.third .commentList {
  margin: 15px 0 0;
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between; }
  @media screen and (max-width: 768px) {
    .INDEX.third .commentList {
      display: block; } }
  .INDEX.third .commentList li {
    list-style: none;
    width: 23%;
    border: #f9ba00 1px solid;
    border-radius: 8px;
    padding: 20px; }
    @media screen and (max-width: 768px) {
      .INDEX.third .commentList li {
        width: 100%;
        padding: 15px;
        margin: 0 0 15px; }
        .INDEX.third .commentList li:last-child {
          margin: 0; } }
    .INDEX.third .commentList li dl {
      font-size: 18px;
      color: #433f3f; }
      @media screen and (max-width: 768px) {
        .INDEX.third .commentList li dl {
          font-size: 13px; } }
      .INDEX.third .commentList li dl dt, .INDEX.third .commentList li dl dd {
        line-height: 34px;
        background: url("../../../images/index/line_dotto.png") repeat; }
        @media screen and (max-width: 768px) {
          .INDEX.third .commentList li dl dt, .INDEX.third .commentList li dl dd {
            line-height: 1.6; } }
      .INDEX.third .commentList li dl dt {
        text-align: center; }
        @media screen and (max-width: 768px) {
          .INDEX.third .commentList li dl dt {
            font-size: 17px; } }
      .INDEX.third .commentList li dl dd {
        text-align: justify; }

.INDEX.fors .ceo {
  margin: 15px 0 0;
  border: #86ac27 1px solid;
  border-radius: 8px;
  padding: 35px;
  font-size: 17px;
  line-height: 1.6; }
  @media screen and (max-width: 768px) {
    .INDEX.fors .ceo {
      padding: 15px;
      font-size: 13px; } }
  .INDEX.fors .ceo figure {
    width: 230px;
    float: left; }
    @media screen and (max-width: 768px) {
      .INDEX.fors .ceo figure {
        float: none;
        width: 60%;
        margin: 0 auto 15px; }
        .INDEX.fors .ceo figure img {
          width: 100%; } }
  .INDEX.fors .ceo div.text {
    width: calc(100% - 260px);
    float: right; }
    @media screen and (max-width: 768px) {
      .INDEX.fors .ceo div.text {
        float: none;
        width: auto; } }

.INDEX.fifth .img {
  border: #49c1f0 1px solid;
  overflow: hidden;
  border-radius: 8px;
  position: relative; }
  .INDEX.fifth .img img {
    width: 100%; }
  .INDEX.fifth .img .amazon-btn {
    width: 410px;
    position: absolute;
    top: 465px;
    left: 50px; }
    @media screen and (max-width: 768px) {
      .INDEX.fifth .img .amazon-btn {
        width: 88%;
        display: block;
        margin: 15px auto;
        position: relative;
        top: 0;
        left: 0; } }

.INDEX.six .metaBOx {
  background: #f9f9f9;
  border: #3b3b3b 1px solid;
  border-radius: 8px;
  padding: 30px;
  font-size: 14px; }
  @media screen and (max-width: 768px) {
    .INDEX.six .metaBOx {
      padding: 15px; } }
  .INDEX.six .metaBOx h4 {
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    margin: 20px 0 10px; }
  .INDEX.six .metaBOx table {
    border: #3b3b3b 1px solid;
    border-collapse: collapse;
    width: 100%;
    background: #FFF; }
    @media screen and (max-width: 768px) {
      .INDEX.six .metaBOx table {
        display: block; }
        .INDEX.six .metaBOx table tbody, .INDEX.six .metaBOx table tr, .INDEX.six .metaBOx table th, .INDEX.six .metaBOx table td {
          display: block; } }
    @media screen and (max-width: 768px) {
      .INDEX.six .metaBOx table tbody tr:last-child td {
        border: none; } }
    .INDEX.six .metaBOx table tbody tr th, .INDEX.six .metaBOx table tbody tr td {
      padding: 5px 30px;
      border: #3b3b3b 1px solid;
      text-align: left;
      vertical-align: middle; }
      @media screen and (max-width: 768px) {
        .INDEX.six .metaBOx table tbody tr th, .INDEX.six .metaBOx table tbody tr td {
          padding: 15px;
          border: none;
          border-bottom: #3b3b3b 1px solid; } }
    .INDEX.six .metaBOx table tbody tr th {
      width: 30%; }
      @media screen and (max-width: 768px) {
        .INDEX.six .metaBOx table tbody tr th {
          width: auto;
          background: #f2f2f2; } }
  .INDEX.six .metaBOx .context {
    text-align: center; }
    @media screen and (max-width: 768px) {
      .INDEX.six .metaBOx .context {
        text-align: justify;
        font-size: 12px; } }

.INDEX.six .product {
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  padding: 0 0 30px;
  margin: 0 0 30px;
  border-bottom: #3b3b3b 1px dotted; }
  @media screen and (max-width: 768px) {
    .INDEX.six .product {
      display: block;
      padding: 0 0 15px;
      margin: 0 0 15px; } }
  .INDEX.six .product .leftBlock {
    width: 55%;
    padding-right: 40px;
    border-right: #3b3b3b 1px dotted; }
    @media screen and (max-width: 768px) {
      .INDEX.six .product .leftBlock {
        width: auto;
        padding: 0;
        border: none; } }
    .INDEX.six .product .leftBlock dl {
      position: relative;
      font-size: 14px;
      margin: 0 0 10px; }
      .INDEX.six .product .leftBlock dl:last-of-type {
        margin: 0; }
      .INDEX.six .product .leftBlock dl dt {
        position: absolute; }
      .INDEX.six .product .leftBlock dl dd {
        padding-left: 72px;
        text-align: justify; }
        .INDEX.six .product .leftBlock dl dd:before {
          content: ':';
          margin: 0 4px 0 0;
          display: inline-block; }
  .INDEX.six .product .rightBlock {
    width: 45%;
    padding-left: 40px; }
    @media screen and (max-width: 768px) {
      .INDEX.six .product .rightBlock {
        width: auto;
        padding: 0;
        margin: 25px 0 0; } }
    .INDEX.six .product .rightBlock dl {
      margin: 0 0 10px; }
      .INDEX.six .product .rightBlock dl:last-of-type {
        margin: 0; }

.COMPANY.first .mainphoto {
  margin: 0 0 32px; }
  @media screen and (max-width: 768px) {
    .COMPANY.first .mainphoto {
      margin: 0 0 24px; } }
  .COMPANY.first .mainphoto img {
    width: 100%; }

.COMPANY.first .profile {
  margin: 32px 0; }
  @media screen and (max-width: 768px) {
    .COMPANY.first .profile {
      margin: 24px 0; } }
  .COMPANY.first .profile figure {
    float: left;
    width: 38%; }
    @media screen and (max-width: 768px) {
      .COMPANY.first .profile figure {
        width: 56%;
        margin: 0 auto 16px;
        float: none; } }
    .COMPANY.first .profile figure img {
      width: 100%; }
  .COMPANY.first .profile .text {
    float: right;
    width: 62%;
    padding-left: 45px;
    font-size: 15px; }
    @media screen and (max-width: 768px) {
      .COMPANY.first .profile .text {
        font-size: 13px;
        float: none;
        padding: 0;
        width: 100%; } }
    .COMPANY.first .profile .text p {
      margin: 0 0 20px;
      text-align: justify; }
      @media screen and (max-width: 768px) {
        .COMPANY.first .profile .text p {
          margin: 0 0 12px; } }
      .COMPANY.first .profile .text p:last-of-type {
        margin: 0; }

.COMPANY.first .companyinfo,
.COMPANY.first .story {
  margin: 32px 0; }
  @media screen and (max-width: 768px) {
    .COMPANY.first .companyinfo,
    .COMPANY.first .story {
      margin: 24px 0; } }

.COMPANY.first dl.detail {
  font-size: 18px;
  padding: 0 80px;
  margin: 0 0 8px; }
  @media screen and (max-width: 768px) {
    .COMPANY.first dl.detail {
      padding: 0;
      font-size: 13px; } }
  .COMPANY.first dl.detail.full {
    padding: 0; }
    .COMPANY.first dl.detail.full dt {
      width: 15%; }
      @media screen and (max-width: 768px) {
        .COMPANY.first dl.detail.full dt {
          width: auto; } }
    .COMPANY.first dl.detail.full dd {
      width: 85%; }
      @media screen and (max-width: 768px) {
        .COMPANY.first dl.detail.full dd {
          width: auto; } }
  .COMPANY.first dl.detail dt {
    width: 20%;
    float: left;
    font-weight: 500; }
    @media screen and (max-width: 768px) {
      .COMPANY.first dl.detail dt {
        font-size: 14px;
        font-weight: 700;
        float: none;
        width: auto; } }
  .COMPANY.first dl.detail dd {
    width: 80%;
    float: right; }
    @media screen and (max-width: 768px) {
      .COMPANY.first dl.detail dd {
        text-align: justify;
        float: none;
        width: auto; } }

.COMPANY.first .gmap {
  margin: 0 0 32px; }
  @media screen and (max-width: 768px) {
    .COMPANY.first .gmap {
      margin: 0 0 24px; } }
  .COMPANY.first .gmap iframe {
    vertical-align: bottom;
    height: 400px; }
    @media screen and (max-width: 768px) {
      .COMPANY.first .gmap iframe {
        height: 200px; } }

.COMPANY.first .product {
  margin: 32px 0 0; }
  @media screen and (max-width: 768px) {
    .COMPANY.first .product {
      margin: 24px 0 0; } }
  .COMPANY.first .product li {
    text-align: center;
    font-size: 16px; }
    @media screen and (min-width: 769px) {
      .COMPANY.first .product li {
        list-style: none;
        width: 31%;
        float: left;
        margin: 0 3.5% 3.5% 0; }
        .COMPANY.first .product li:nth-child(3n+1) {
          clear: both; }
        .COMPANY.first .product li:nth-child(3n+3) {
          margin-right: 0; }
        .COMPANY.first .product li:nth-last-child(-n+3) {
          margin-bottom: 0; } }
    @media screen and (max-width: 768px) {
      .COMPANY.first .product li {
        list-style: none;
        width: 48.5%;
        float: left;
        margin: 0 3% 3% 0; }
        .COMPANY.first .product li:nth-child(2n+1) {
          clear: both; }
        .COMPANY.first .product li:nth-child(2n+2) {
          margin-right: 0; }
        .COMPANY.first .product li:nth-last-child(-n+2) {
          margin-bottom: 0; } }
    @media screen and (max-width: 768px) {
      .COMPANY.first .product li {
        font-size: 13px; } }
    .COMPANY.first .product li figure {
      border: #838383 1px solid;
      border-radius: 8px;
      overflow: hidden;
      margin: 0 0 12px; }
      @media screen and (max-width: 768px) {
        .COMPANY.first .product li figure {
          margin: 0 0 4px; } }
      .COMPANY.first .product li figure img {
        width: 100%; }

.COMPANY.second dl.desp {
  font-size: 16px;
  margin: 32px 0 0; }
  @media screen and (max-width: 768px) {
    .COMPANY.second dl.desp {
      margin: 24px 0 0;
      font-size: 13px; } }
  .COMPANY.second dl.desp dt {
    background: #888;
    text-align: center;
    padding: 8px 0;
    color: #FFF;
    border-radius: 6px; }
    @media screen and (max-width: 768px) {
      .COMPANY.second dl.desp dt {
        font-size: 14px; } }
  .COMPANY.second dl.desp dd {
    text-align: center;
    margin: 12px 0 0; }
    @media screen and (max-width: 768px) {
      .COMPANY.second dl.desp dd {
        margin: 8px 0 0; } }

.COMPANY.second form {
  margin: 16px 0 0;
  font-size: 15px; }

.COMPANY.second table {
  width: 100%;
  border-collapse: collapse; }
  @media screen and (max-width: 768px) {
    .COMPANY.second table {
      display: block; }
      .COMPANY.second table tbody {
        display: block; }
        .COMPANY.second table tbody tr {
          display: block; } }
  .COMPANY.second table th, .COMPANY.second table td {
    padding: 16px 0;
    vertical-align: middle; }
    @media screen and (max-width: 768px) {
      .COMPANY.second table th, .COMPANY.second table td {
        padding: 6px 0;
        display: block; } }
  .COMPANY.second table th {
    width: 25%;
    font-weight: 500; }
    .COMPANY.second table th.none:before {
      display: none; }
    @media screen and (max-width: 768px) {
      .COMPANY.second table th.none {
        background: #f2f2f2;
        padding: 8px 15px; } }
    @media screen and (max-width: 768px) {
      .COMPANY.second table th {
        width: auto; } }
    .COMPANY.second table th:after {
      content: attr(data-eng);
      display: block;
      font-size: 13px;
      color: #888;
      line-height: 1; }
      @media screen and (max-width: 768px) {
        .COMPANY.second table th:after {
          display: inline-block;
          margin: 0 0 0 20px;
          font-size: 10px; } }
    .COMPANY.second table th:before {
      content: '任意';
      font-size: 13px;
      float: right;
      background: #888;
      color: #FFF;
      padding: 4px 16px;
      border-radius: 5px;
      margin: 4px 0 0; }
      @media screen and (max-width: 768px) {
        .COMPANY.second table th:before {
          font-size: 12px;
          padding: 2px 16px;
          margin: 2px 0 0; } }
    .COMPANY.second table th.hiss:before {
      content: '必須';
      background: #ea5c9d; }
  .COMPANY.second table td {
    padding-left: 45px; }
    @media screen and (max-width: 768px) {
      .COMPANY.second table td {
        padding-left: 0; } }
    .COMPANY.second table td input,
    .COMPANY.second table td textarea {
      border: #c9c9c9 1px solid;
      border-radius: 4px;
      width: 100%;
      padding: 6px 12px;
      font-family: "M PLUS Rounded 1c","Yu Gothic","游ゴシック",YuGothic,"Hiragino Kaku Gothic Pro","メイリオ", Meiryo,"MS Pゴシック", Helvetica, Arial, Verdana, sans-serif;
      font-weight: 500;
      -webkit-font-feature-settings: "palt";
      font-feature-settings: "palt";
      letter-spacing: 0.024em;
      -webkit-transition: all 200ms 0ms ease-in-out;
      -moz-transition: all 200ms 0ms ease-in-out;
      -ms-transition: all 200ms 0ms ease-in-out;
      -o-transition: all 200ms 0ms ease-in-out;
      transition: all 200ms 0ms ease-in-out; }
      .COMPANY.second table td input:focus,
      .COMPANY.second table td textarea:focus {
        border-color: #FFF;
        box-shadow: 0 0 32px rgba(0, 0, 0, 0.2); }
    .COMPANY.second table td textarea {
      height: 180px; }

.COMPANY.second .send {
  margin: 24px 0 0;
  text-align: center; }
  @media screen and (max-width: 768px) {
    .COMPANY.second .send {
      margin: 12px 0 0; } }
  .COMPANY.second .send input {
    width: 240px;
    font-size: 20px;
    font-weight: 700;
    color: #FFF;
    background: #fa5e45;
    border: none;
    padding: 12px 0;
    border-radius: 999px;
    cursor: pointer;
    display: inline-block;
    margin: 0 6px; }
    .COMPANY.second .send input:hover {
      background: #fb806d; }
    @media screen and (max-width: 768px) {
      .COMPANY.second .send input {
        display: block;
        width: 100%;
        padding: 10px 0;
        font-size: 18px;
        margin: 0 0 12px; }
        .COMPANY.second .send input:last-of-type {
          margin: 0; } }

#WRAPPER, #FOOTER {
  position: relative;
  top: 104px; }
  @media screen and (max-width: 768px) {
    #WRAPPER, #FOOTER {
      top: 60px; } }

.MODULE.wrap {
  padding: 32px 0; }
  @media screen and (max-width: 768px) {
    .MODULE.wrap {
      padding: 24px 15px; } }
  .MODULE.wrap.no-top {
    padding-top: 0; }
  .MODULE.wrap.no-bottom {
    padding-bottom: 0; }

.MODULE .bnr img {
  width: 100%; }

.MODULE h2.radius {
  border-radius: 999px;
  font-size: 34px;
  text-align: center;
  color: #FFF;
  padding: 8px 0; }
  .MODULE h2.radius.s {
    border-radius: 6px;
    font-size: 28px;
    padding: 16px 0; }
  @media screen and (max-width: 768px) {
    .MODULE h2.radius {
      font-size: 18px;
      padding: 12px 15px; }
      .MODULE h2.radius.s {
        font-size: 16px;
        padding: 12px 15px; } }
  .MODULE h2.radius.orange {
    background: #fa5e45; }
  .MODULE h2.radius.sky {
    background: #49c1f0; }
  .MODULE h2.radius.pink {
    background: #ea5c9d; }
  .MODULE h2.radius.yellow {
    background: #f9ba00; }
  .MODULE h2.radius.green {
    background: #86ac27; }

.MODULE .radius-bottom {
  color: #5f5f5f;
  font-size: 20px;
  text-align: center;
  margin: 12px 0 20px; }
  @media screen and (max-width: 768px) {
    .MODULE .radius-bottom {
      margin: 8px 0 12px;
      font-size: 15px; } }
