.COMPANY{
    &.first{
        .mainphoto{
            margin: 0 0 32px;
            @media screen and (max-width:768px){
                margin: 0 0 24px;   
            }
            img{
                width: 100%;
            }
        }
        .profile{
            margin: 32px 0;
            @media screen and (max-width:768px){
                margin: 24px 0;   
            }
            figure{
                float: left;
                width: 38%;
                @media screen and (max-width:768px){
                    width: 56%;
                    margin: 0 auto 16px;
                    float: none;
                }
                img{
                    width: 100%;
                }
            }
            .text{
                float: right;
                width: 62%;
                padding-left: 45px;
                font-size: 15px;
                @media screen and (max-width:768px){
                    font-size: 13px;
                    float: none;
                    padding: 0;
                    width: 100%;
                }
                p{
                    margin: 0 0 20px;
                    text-align: justify;
                    @media screen and (max-width:768px){
                        margin: 0 0 12px;   
                    }
                    &:last-of-type{
                        margin: 0;
                    }
                }
            }
        }
        .companyinfo,
        .story{
            margin: 32px 0;
            @media screen and (max-width:768px){
                margin: 24px 0;
            }
        }
        dl.detail{
            @extend .clearfix;
            font-size: 18px;
            padding: 0 80px;
            margin: 0 0 8px;
            @media screen and (max-width:768px){
                padding: 0;
                font-size: 13px;
            }
            &.full{
                padding: 0;
                dt{
                    width: 15%;
                    @media screen and (max-width:768px){
                        width: auto;    
                    }
                }
                dd{
                    width: 85%;
                    @media screen and (max-width:768px){
                        width: auto;    
                    }
                }
            }
            dt{
                width: 20%;
                float: left;
                font-weight: 500;
                @media screen and (max-width:768px){
                    font-size: 14px;
                    font-weight: 700;
                    float: none;
                    width: auto;
                }
            }
            dd{
                width: 80%;
                float: right;
                @media screen and (max-width:768px){
                    text-align: justify;
                    float: none;
                    width: auto;
                }
            }
        }
        .gmap{
            margin: 0 0 32px;
            @media screen and (max-width:768px){
                margin: 0 0 24px;   
            }
            iframe{
                vertical-align: bottom;
                height: 400px;
                @media screen and (max-width:768px){
                    height: 200px;   
                }
            }
        }
        .product{
            margin: 32px 0 0;
            @media screen and (max-width:768px){
                margin: 24px 0 0;   
            }
            li{
                @media screen and (min-width:769px){
                    @include clum(3,31%,3.5%);
                }
                @media screen and (max-width:768px){
                    @include clum(2,48.5%,3%);
                }
                text-align: center;
                font-size: 16px;
                @media screen and (max-width:768px){
                    font-size: 13px;   
                }
                figure{
                    border: #838383 1px solid;
                    border-radius: 8px;
                    overflow: hidden;
                    margin: 0 0 12px;
                    @media screen and (max-width:768px){
                        margin: 0 0 4px;   
                    }
                    img{
                        width: 100%;
                    }
                }
            }
        }
    }
    &.second{
        dl.desp{
            font-size: 16px;
            margin: 32px 0 0;
            @media screen and (max-width:768px){
                margin: 24px 0 0;
                font-size: 13px;
            }
            dt{
                background: #888;
                text-align: center;
                padding: 8px 0;
                color: #FFF;
                border-radius: 6px;
                @media screen and (max-width:768px){
                    font-size: 14px;   
                }
            }
            dd{
                text-align: center;
                margin: 12px 0 0;
                @media screen and (max-width:768px){
                    margin: 8px 0 0;   
                }
            }
        }
        form{
            margin: 16px 0 0;
            font-size: 15px;
        }
        table{
            width: 100%;
            border-collapse: collapse;
            @media screen and (max-width:768px){
                display: block;
                tbody{
                    display: block;
                    tr{
                        display: block;
                    }
                }
            }
            th,td{
                padding: 16px 0;
                vertical-align: middle;
                @media screen and (max-width:768px){
                    padding: 6px 0;
                    display: block;   
                }
            }
            th{
                width: 25%;
                font-weight: 500;
                &.none{
                    &:before{
                        display: none;
                    }
                    @media screen and (max-width:768px){
                        background: #f2f2f2;
                        padding: 8px 15px;
                    }
                }
                @media screen and (max-width:768px){
                    width: auto;
                }
                &:after{
                    content: attr(data-eng);
                    display: block;
                    font-size: 13px;
                    color: #888;
                    line-height: 1;
                    @media screen and (max-width:768px){
                        display: inline-block;
                        margin: 0 0 0 20px;
                        font-size: 10px;
                    }
                }
                &:before{
                    content: '任意';
                    font-size: 13px;
                    float: right;
                    background: #888;
                    color: #FFF;
                    padding: 4px 16px;
                    border-radius: 5px;
                    margin: 4px 0 0;
                    @media screen and (max-width:768px){
                        font-size: 12px;
                        padding: 2px 16px;
                        margin: 2px 0 0;
                    }
                }
                &.hiss{
                    &:before{
                        content: '必須';
                        background: $pink;
                    }
                }
            }
            td{
                padding-left: 45px;
                @media screen and (max-width:768px){
                    padding-left: 0;   
                }
                input,
                textarea{
                    border: darken($border,8%) 1px solid;
                    border-radius: 4px;
                    width: 100%;
                    padding: 6px 12px;
                    @include font();
                    @include transition(200ms);
                    &:focus{
                        border-color: #FFF;
                        box-shadow: 0 0 32px rgba(#000,.2);
                    }
                }
                textarea{
                    height: 180px;
                }
            }
        }
        .send{
            margin: 24px 0 0;
            text-align: center;
            @media screen and (max-width:768px){
                margin: 12px 0 0;   
            }
            input{
                width: 240px;
                font-size: 20px;
                font-weight: 700;
                color: #FFF;
                background: $orange;
                border: none;
                padding: 12px 0;
                border-radius: 999px;
                cursor: pointer;
                display: inline-block;
                margin: 0 6px;
                &:hover{
                    background: lighten($orange,8%);
                }
                @media screen and (max-width:768px){
                    display: block;
                    width: 100%;
                    padding: 10px 0;
                    font-size: 18px;
                    margin: 0 0 12px;
                    &:last-of-type{
                        margin: 0;
                    }
                }
            }
        }
    }
}