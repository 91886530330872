@charset "utf-8";

@mixin headerStyle(
	$header-width:auto,
	$header-height:auto,
	$header-x:0,
	$header-y:0,
	$header-top-padding:0,
	$header-right-padding:0,
	$header-bottom-padding:0,
	$header-left-padding:0,
	$header-attachment:relative,
	$header-nav-position:none,
	$header-background:#FFF,
	$header-zindex:1,
	
	$logo-class:'.header-logo',
	$logo-width:auto,
	$logo-height:auto,
	$logo-position:none,
	$logo-top-margin:0,
	$logo-right-margin:0,
	$logo-bottom-margin:0,
	$logo-left-margin:0,
	
	$nav-class:'.header-nav',
	$nav-position:none,
	$nav-top-margin:0,
	$nav-right-margin:0,
	$nav-bottom-margin:0,
	$nav-left-margin:0,
	
	$nav-list:none,
	$nav-list-side:0,
	$nav-list-top:0,
	$responsive:'false',
	
	$responsive-color:$normal,
	$responsive-background:#FFF,
	$responsive-border:#EEE,
	$responsive-shadow:false
){
	width:$header-width;
	height:$header-height;
	position:$header-attachment;
	top:$header-x;
	left:$header-y;
	padding:$header-top-padding $header-right-padding $header-bottom-padding $header-right-padding;
	background:$header-background;
	z-index:$header-zindex;
	#{$logo-class}{
		float:$logo-position;
		margin:$logo-top-margin $logo-right-margin $logo-bottom-margin $logo-left-margin;
		img{
			width:$logo-width;
			height:$logo-height;
		}
	}
	#{$nav-class}{
		float:$nav-position;
		margin:$nav-top-margin $nav-right-margin $nav-bottom-margin $nav-left-margin;
		li{
			list-style:none;
			margin-top:$nav-list-top;
			float:$nav-list;
			@if $nav-list == left {
				margin-left:$nav-list-side;
				&:first-child{
					margin-left:0;	
				}
			}
			@if $nav-list == right {
				margin-right:$nav-list-side;
				&:first-child{
					margin-right:0;	
				}
			}
		}
		@if $responsive == 'false'{
			ul{
				display:block !important;
			}
		}
		@if $responsive == 'true'{
			background:url(../../images/common/responsive_nav_2x.png) no-repeat 0 0;
			background-size: 32px 64px;
			width:32px;
			height:32px;
			cursor:pointer;
			&.addClose{
				background:url(../../images/common/responsive_nav_2x.png) no-repeat 0 -32px;
				background-size: 32px 64px;
			}
			ul{
				@if $responsive-shadow == 'true'{
					-webkit-box-shadow:0 4px 4px rgba(0,0,0,.12);
					box-shadow:0 4px 4px rgba(0,0,0,.12);
				}
				position:absolute;
				width:100%;
				top:$header-height;
				left:0;
				display:none;
				li{
					margin:0;
					border-top:$responsive-border 1px solid;
					a{
						background:$responsive-background;
						color:$responsive-color;
						display:block;
						padding:$nav-list-top $nav-list-side;
						&:hover{
							opacity:.9;
						}
					}
				}
			}
		}
	}
}


#HEADER{
	/* PC STYLE
	----------------------------------------*/
	@media screen and (min-width:769px){
		@include headerStyle(
			$header-width:100%,
			$header-height:$pc-head,
			$header-x:0,
			$header-y:0,
			$header-top-padding:0,
			$header-right-padding:0,
			$header-bottom-padding:0,
			$header-left-padding:0,
			$header-attachment:fixed,
			$header-nav-position:none,
			$header-background:#FFF,
			$header-zindex:1000,
			
			$logo-class:'.logo',
			$logo-width:200px,
			$logo-height:auto,
			$logo-position:left,
			$logo-top-margin:36px,
			$logo-right-margin:0,
			$logo-bottom-margin:0,
			$logo-left-margin:0,
			
			$nav-class:'.gnavi',
			$nav-position:left,
			$nav-top-margin:40px,
			$nav-right-margin:0,
			$nav-bottom-margin:0,
			$nav-left-margin:30px,
			
			$nav-list:left,
			$nav-list-side:20px,
			$nav-list-top:0			
		);
	}
	
	/* SP STYLE
	----------------------------------------*/
	@media screen and (max-width:768px){
		@include headerStyle(
			$header-width:100%,
			$header-height:$sp-head,
			$header-x:0,
			$header-y:0,
			$header-top-padding:0,
			$header-right-padding:0,
			$header-bottom-padding:0,
			$header-left-padding:0,
			$header-attachment:fixed,
			$header-nav-position:none,
			$header-background:#FFF,
			$header-zindex:1000,
			
			$logo-class:'.logo',
			$logo-width:auto,
			$logo-height:30px,
			$logo-position:left,
			$logo-top-margin:15px,
			$logo-right-margin:0,
			$logo-bottom-margin:0,
			$logo-left-margin:15px,
			
			$nav-class:'.gnavi',
			$nav-position:right,
			$nav-top-margin:15px,
			$nav-right-margin:12px,
			$nav-bottom-margin:0,
			$nav-left-margin:0,
			
			$nav-list:none,
			$nav-list-side:15px,
			$nav-list-top:16px,
			
			$responsive:'true',
			$responsive-color:$normal,
			$responsive-background:#FFF,
			$responsive-border:#DDD,
			$responsive-shadow:'true'
			
		);		
	}
	
	/* CUSTUM STYLE
	----------------------------------------*/
    font-size: 24px;
	a{
		color:$normal;
        &:hover{
            opacity: .8;
        }
	}
    .logo{
        a{
            color: $normal;
            font-weight: 700;
            position: relative;
            line-height: 1;
            &:hover{
                color: darken($sky,6%);
                &:after{
                    content: '';
                    width: 100%;
                    height: 2px;
                    background: $sky;
                    position: absolute;
                    left: 0;
                    bottom: -4px;
                    border-radius: 6px;
                }   
            }
        }
    }
    .gnavi{
        line-height: 1;
        li{
            border-left:$normal 1px solid;
            padding: 0 0 0 20px;
            a{
                position: relative;
                &:hover{
                    color: darken($sky,6%);
                    &:after{
                        @media screen and (min-width:769px){
                            content: '';
                            width: 100%;
                            height: 2px;
                            background: $sky;
                            position: absolute;
                            left: 0;
                            bottom: -4px;
                            border-radius: 6px;
                        }
                    }
                }
            }
            @media screen and (max-width:768px){
                border: none;
                padding: 0;
                font-size: 18px;
            }
        }
    }
    nav.sns{
        margin: 28px 0 0;
        float: right;
        @media screen and (max-width:768px){
            display: none;
        }
        ul{
            li{
                list-style: none;
                float: left;
                margin: 0 16px 0 0;
                width: 41px;
                img{
                    width: 100%;
                }
                &:last-child{
                    margin: 0;
                }
            }
        }
    }
}