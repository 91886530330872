.INDEX{
    &.firstview{
        figure{
            border: $sky 1px solid;
            border-radius: 8px;
            overflow: hidden;
            @media screen and (max-width:768px){
                border: none;
                border-radius: 0px;
            }
            img{
                width: 100%;
            }
        }
    }
    &.news{
        .newsBox{
            border: $pink 1px solid;
            border-radius: 8px;
            margin: 0 0 40px;
            overflow: hidden;
            @media screen and (max-width:768px){
                margin: 0 0 24px;
            }
            h3{
                color: #FFF;
                text-align: center;
                background: $pink;
                font-size: 24px;
                padding: 12px 0;
                @media screen and (max-width:768px){
                    font-size: 15px;
                    padding: 12px 0;
                }
            }
            ol{
                height: 260px;
                overflow: auto;
                @media screen and (max-width:768px){
                    height: 180px;
                }
                li{
                    position: relative;
                    border-bottom: #999 1px dashed;
                    padding: 8px 12px;
                    font-size: 20px;
                    @media screen and (max-width:768px){
                        padding: 8px 15px;
                        font-size: 13px;
                    }
                    &:last-of-type{
                        border: none;
                    }
                    &:before{
                        content: '';
                        display: inline-block;
                        width: 92px;
                        margin: 0 20px 0 0;
                        font-size: 16px;
                        text-align: center;
                        color: #FFF;
                        border-radius: 999px;
                        @media screen and (max-width:768px){
                            font-size: 13px;
                            margin: 0 0 4px;
                        }
                    }
                    &.ns{
                        &:before{
                            content: 'ニュース';
                            background: #999;
                        }
                    }
                    &.rs{
                        &:before{
                            content: 'リリース';
                            background: $pink;
                        }
                    }
                    a{
                        color: #828282;
                        @media screen and (max-width:768px){
                            display: block;   
                        }
                        &:hover{
                            color: $pink;
                        }
                        time{
                            display: inline-block;
                            &:after{
                                content: '・・・・・';
                                display: inline-block;
                                margin: 0 12px;
                            }
                        }
                    }
                }
            }
        }
    }
    &.first{
        .list{
            @include flex();
            @media screen and (max-width:768px){
                display: block;   
            }
            li{
                width: 48.5%;
                list-style: none;
                border: $orange 1px solid;
                border-radius: 8px;
                overflow: hidden;
                margin: 0 0 30px;
                @media screen and (min-width:769px){
                    &:nth-last-child(-n+2){
                        margin: 0;
                    }
                }
                @media screen and (max-width:768px){
                    width: 100%;
                    margin: 0 0 15px;
                    &:last-child{
                        margin: 0;
                    }
                }
                figure{
                    img{
                        width: 100%;
                    }
                }
                dl{
                    padding: 15px;
                    text-align: center;
                    dt{
                        background: $orange;
                        border-radius: 999px;
                        color: #FFF;
                        font-size: 26px;
                        @media screen and (max-width:768px){
                            font-size: 16px;     
                        }
                    }
                    dd{
                        margin: 12px 0 0;
                        font-size: 16px;
                        color: #433f3f;
                        @media screen and (max-width:768px){
                            font-size: 13px;
                            text-align: justify;
                            br{
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
    &.second{
        .boxAbout{
            border: $sky 1px solid;
            border-radius: 8px;
            overflow: hidden;
        }
        h3.textLarge{
            text-align: center;
            margin: 20px 0;
            font-size: 28px;
            @media screen and (max-width:768px){
                margin: 15px;
                font-size: 20px;
                line-height: 1.6;
            }
            strong{
                color: $sky;
                display: inline-block;
                position: relative;
                &:before,&:after{
                    content: '';
                    width: 100%;
                    height: 1px;
                    background: $sky;
                    position: absolute;
                    left: 0;
                    bottom: -2px;
                }
                &:after{
                    bottom: -5px;
                }
            }
        }
        .floating{
            margin: 0 20px;
            @media screen and (max-width:768px){
                margin: 0 15px;   
            }
            figure{
                float: left;
                margin: 0 56px 0 0;
                text-align: center;
                @media screen and (max-width:768px){
                    float: none;
                    margin: 0;
                    img{
                        width: 64px;
                    }
                }
                figcaption{
                    font-size: 22px;
                    @media screen and (max-width:768px){
                        font-size: 16px;
                        margin: 4px 0 0;
                    }
                }
            }
            p{
                padding: 10px 0 0;
            }
        }
        figure.full{
            text-align: center;
            margin: 20px 20px 0;
            @media screen and (max-width:768px){
                margin: 15px;
            }
            img{
                max-width: 100%;
            }
        }
        
        .itemList{
            margin: 20px 8px 0;
            border-top: $sky 1px solid;
            @media screen and (max-width:768px){
                margin: 20px 0;   
            }
            li{
                background: #fffff4;
                border-bottom: $sky 1px solid;
                padding: 20px;
                color: #3b3b3b;
                font-size: 17px;
                @media screen and (max-width:768px){
                    padding: 15px;
                    font-size: 13px;
                }
                &:nth-child(2n+2){
                    background: #FFF;
                }
                &:last-child{
                    padding-left: 105px;
                    @media screen and (max-width:768px){
                        padding-left: 15px;
                    }
                }
                figure{
                    text-align: center;
                    width: 30%;
                    padding-right: 40px;
                    float: left;
                    @media screen and (max-width:768px){
                        width: auto;
                        float: none;
                        padding: 0;
                        margin: 0 0 15px;
                        img{
                            width: 64px;
                        }
                    }
                    figcaption{
                        font-size: 22px;
                        @media screen and (max-width:768px){
                            font-size: 16px;   
                        }
                    }
                }
                div.text{
                    @media screen and (max-width:768px){
                        br{
                            display: none;
                            text-align: justify;
                        }   
                    }
                }
                .harf{
                    width: 48%;
                    float: left;
                    @media screen and (max-width:768px){
                        width: auto;
                        float: none;
                    }
                    &:last-of-type{
                        float: right;
                        @media screen and (max-width:768px){
                            float: none;
                        }
                    }
                    figure{
                        width: auto;
                    }
                }
            }
        }
        
        .eating{
            @include flex();
            margin: 20px;
            position: relative;
            @media screen and (max-width:768px){
                display: block;
                margin: 15px;
            }
            &:before,
            &:after{
                content: '';
                position: absolute;
            }
            &:before{
                background: url("../../../images/index/cross_sky.png") no-repeat;
                width: 56px;
                height: 56px;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
                @media screen and (max-width:768px){
                    width: 28px;
                    height: 28px;
                    background-size: 28px; 
                    transform: translateX(-50%) translateY(-110%);
                }
            }
            &:after{
                background: url("../../../images/index/arrow_blue.png") no-repeat;
                width: 91px;
                height: 39px;
                bottom: -50px;
                left: 50%;
                transform: translateX(-50%);
                z-index: 2;
                @media screen and (max-width:768px){
                    display: none;
                }
            }
            li{
                list-style: none;
                width: 45%;
                border: $sky 2px solid;
                border-radius: 12px;
                padding: 10px 25px;
                background: #fffff4;
                @media screen and (max-width:768px){
                    width: auto;
                    padding: 15px;
                    margin: 0 0 56px;
                    &:last-child{
                        margin: 0;
                    }
                }
                h4{
                    text-align: center;
                    font-size: 24px;
                    color: $sky;
                    margin: 0 0 8px;
                    @media screen and (max-width:768px){
                        font-size: 17px;   
                    }
                }
                figure{
                    img{
                        float: left;
                        margin: 0 24px 0 0;
                        @media screen and (max-width:768px){
                            float: none;
                            width: 100%;
                            margin: 0 0 15px;
                        }
                    }
                    figcaption{
                        white-space: nowrap;
                        font-size: 15px;
                        padding: 10px 0 0;
                        @media screen and (max-width:768px){
                            text-align: center;
                            font-size: 17px;
                        }
                    }
                }
                .text-center{
                    padding: 10px 20px;
                    @media screen and (max-width:768px){
                        padding: 15px;
                        padding-bottom: 0;
                    }
                }
            }
        }
        
        figure.last{
            position: relative;
            img{
                width: 100%;
            }
            .amazon-btn{
                width: 376px;
                position: absolute;
                top: 346px;
                right: 22px;
                @media screen and (max-width:768px){
                    width: 88%;
                    display: block;
                    margin: 15px auto;
                    position: relative;
                    top: 0;
                    right: 0;
                }
            }
        }
    }
    &.third{
        .comment{
            position: relative;
            border-radius: 8px;
            border: $yellow 1px solid;
            overflow: hidden;
            img{
                width: 100%;
            }
        }
        .commentList{
            margin: 15px 0 0;
            @include flex();
            @media screen and (max-width:768px){
                display: block;    
            }
            li{
                list-style: none;
                width: 23%;
                border: $yellow 1px solid;
                border-radius: 8px;
                padding: 20px;
                @media screen and (max-width:768px){
                    width: 100%;
                    padding: 15px;
                    margin: 0 0 15px;
                    &:last-child{
                        margin: 0;
                    }
                }
                dl{
                    font-size: 18px;
                    color: #433f3f;
                    @media screen and (max-width:768px){
                        font-size: 13px;   
                    }
                    dt,dd{
                        line-height: 34px;
                        background: url("../../../images/index/line_dotto.png") repeat;
                        @media screen and (max-width:768px){
                            line-height: 1.6;   
                        }
                    }
                    dt{
                        text-align: center;
                        @media screen and (max-width:768px){
                            font-size: 17px;   
                        }
                    }
                    dd{
                        text-align: justify;
                    }
                }
            }
        }
    }
    &.fors{
        .ceo{
            margin: 15px 0 0;
            border: $green 1px solid;
            border-radius: 8px;
            padding: 35px;
            font-size: 17px;
            line-height: 1.6;
            @media screen and (max-width:768px){
                padding: 15px;   
                font-size: 13px;
            }
            figure{
                width: 230px;
                float: left;
                @media screen and (max-width:768px){
                    float: none;
                    width: 60%;
                    margin: 0 auto 15px;
                    img{
                        width: 100%;
                    }
                }
            }
            div.text{
                width: calc(100% - 260px);
                float: right;
                @media screen and (max-width:768px){
                    float: none;
                    width: auto;
                }
            }
        }
    }
    &.fifth{
        .img{
            border: $sky 1px solid;
            overflow: hidden;
            border-radius: 8px;
            position: relative;
            img{
                width: 100%;
            }
            .amazon-btn{
                width: 410px;
                position: absolute;
                top: 465px;
                left: 50px;
                @media screen and (max-width:768px){
                    width: 88%;
                    display: block;
                    margin: 15px auto;
                    position: relative;
                    top: 0;
                    left: 0;
                }
            } 
        }
    }
    &.six{
        .metaBOx{
            background: #f9f9f9;
            border: $normal 1px solid;
            border-radius: 8px;
            padding: 30px;
            font-size: 14px;
            @media screen and (max-width:768px){
                padding: 15px;   
            }
            h4{
                text-align: center;
                font-size: 16px;
                font-weight: 700;
                margin: 20px 0 10px;
            }
            table{
                border: $normal 1px solid;
                border-collapse: collapse;
                width: 100%;
                background: #FFF;
                @media screen and (max-width:768px){
                    display: block;
                    tbody,tr,th,td{
                        display: block;
                    }
                }
                tbody{
                    tr{
                        @media screen and (max-width:768px){
                            &:last-child{
                                td{
                                    border: none;
                                }
                            }   
                        }
                        th,td{
                            padding: 5px 30px;
                            border: $normal 1px solid;
                            text-align: left;
                            vertical-align: middle;
                            @media screen and (max-width:768px){
                                padding: 15px;
                                border: none;
                                border-bottom: $normal 1px solid;
                            }
                        }
                        th{
                            width: 30%;
                            @media screen and (max-width:768px){
                                width: auto;
                                background: #f2f2f2;
                            }
                        }
                    }
                }
            }
            .context{
                text-align: center;
                @media screen and (max-width:768px){
                    text-align: justify;
                    font-size: 12px;
                }
            }
        }
        .product{
            @include flex();
            padding: 0 0 30px;
            margin: 0 0 30px;
            border-bottom: $normal 1px dotted;
            @media screen and (max-width:768px){
                display: block;
                padding: 0 0 15px;
                margin: 0 0 15px;
            }
            .leftBlock{
                width: 55%;
                padding-right: 40px;
                border-right: $normal 1px dotted;
                @media screen and (max-width:768px){
                    width: auto;
                    padding: 0;
                    border: none;
                }
                dl{
                    position: relative;
                    font-size: 14px;
                    margin: 0 0 10px;
                    &:last-of-type{
                        margin: 0;
                    }
                    dt{
                        position: absolute;
                    }
                    dd{
                        padding-left: 72px;
                        text-align: justify;
                        &:before{
                            content: ':';
                            margin: 0 4px 0 0;
                            display: inline-block;
                        }
                    }
                }
            }
            .rightBlock{
                width: 45%;
                padding-left: 40px;
                @media screen and (max-width:768px){
                    width: auto;
                    padding: 0;
                    margin: 25px 0 0;
                }
                dl{
                    margin: 0 0 10px;
                    &:last-of-type{
                        margin: 0;
                    }
                }
            }
        }
    }
}