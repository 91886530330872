@charset "utf-8";

@import "module/reset";
@import "module/module";


/* BASE COLOR
------------------------------------------------------------*/
$normal:#3b3b3b;
$link:#3b3b3b;
$border:#DDD;

$sky:#49c1f0;
$pink:#ea5c9d;
$yellow:#f9ba00;
$orange:#fa5e45;
$green:#86ac27;


/* SITE SETTING
------------------------------------------------------------*/
$width:970px;
$pc-head:104px;
$sp-head:60px;


@media screen and (min-width:769px){
	@include default(
		$width, //サイト幅
		15px, //フォントサイズ
		$normal, //フォントカラー
		$link, //リンクカラー
		#FFF //ボディ背景
	);
}

@media screen and (max-width:768px){
	@include default(
		auto, //サイト幅
		13px, //フォントサイズ
		$normal, //フォントカラー
		$link, //リンクカラー
		#FFF //ボディ背景
	);
}

@import "common/header";
@import "common/footer";
@import "page/index";
@import "page/company";

#WRAPPER,#FOOTER{
    position: relative;
    top: $pc-head;
    @media screen and (max-width:768px){
        top: $sp-head;
    }
}

.MODULE{
    &.wrap{
        padding: 32px 0;
        @media screen and (max-width:768px){
            padding: 24px 15px;   
        }
        &.no-top{
            padding-top: 0;
        }
        &.no-bottom{
            padding-bottom: 0;
        }
    }
    .bnr{
        img{
            width: 100%;
        }
    }
    h2.radius{
        border-radius: 999px;
        font-size: 34px;
        text-align: center;
        color: #FFF;
        padding: 8px 0;
        &.s{
            border-radius: 6px;
            font-size: 28px;
            padding: 16px 0;
        }
        @media screen and (max-width:768px){
            font-size: 18px;
            padding: 12px 15px;
            &.s{
                font-size: 16px;
                padding: 12px 15px;
            }
        }
        &.orange{
            background: $orange;
        }
        &.sky{
            background: $sky;
        }
        &.pink{
            background: $pink;
        }
        &.yellow{
            background: $yellow;
        }
        &.green{
            background: $green;
        }
    }
    .radius-bottom{
        color: #5f5f5f;
        font-size: 20px;
        text-align: center;
        margin: 12px 0 20px;
        @media screen and (max-width:768px){
            margin: 8px 0 12px;
            font-size: 15px;
        }
    }
}